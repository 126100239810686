import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Badge, Card, Label, TextInput, useView } from 'components/lib'
import React from 'react'
import { useParams } from 'react-router-dom'

export default function WebhookLog() {
    const view = useView()
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: ['integration', 'webhook_logs', id],
        queryFn: async () => axios.get(`/api/integration/webhook_logs/${id}`).then(res => res.data),
        onError: (e) => view.handleError(e)
    })

    const log = data?.log

    return (
        <Animate>
            <Card title="Webhook Request Log" loading={isLoading}>
                {
                    log ? (
                        <div className='grid gap-4'>
                            <div className='flex items-center gap-2'>
                                <p className='font-medium'>{log.name}</p>
                                <Badge
                                    className='w-fit'
                                    text={log.status_code + ' ' + log.status}
                                    color={log.status_code === 200 ? 'green' : 'red'}
                                />
                            </div>

                            <div>
                                <Label text='Event Type' />
                                <p>{log.event}</p>
                            </div>

                            <TextInput
                                label='Request URL'
                                value={log.url}
                            />

                            <div>
                                <Label text='Request Log' />
                                <pre>
                                    {JSON.stringify(JSON.parse(log.log || '{}'), null, 4)}
                                </pre>
                            </div>
                        </div>
                    ) : (
                        <p>No Log Preview Vailable For This Webhook</p>
                    )
                }
            </Card>
        </Animate>
    )
}
