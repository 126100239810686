/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User, useSocket, useView, Message } from 'components/lib';
import Style from './app.module.scss';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { Link } from 'react-router-dom';


const AppContext = createContext({})
export const useApp = () => useContext(AppContext)

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);
  const view = useView()

  const [items, setItems] = useState([
    { label: 'New Campaign', icon: 'plus', link: '/new_campaign', permission: 'admin', highlighted: true, className: 'mb-[2.5rem]', order: 1 },
    { label: 'Campaigns', icon: 'radio', link: '/campaigns', permission: 'user', order: 2 },
    { label: 'Missed Call Log', icon: 'phone-missed', link: '/campaign/all/missed_call_log', permission: 'user', order: 5 },
    { label: 'Billing', icon: 'credit-card', link: '/billing', permission: 'admin', order: 6 },
    { label: 'Account', icon: 'user', link: '/account', permission: 'user', order: 7 },
    { label: 'Users', icon: 'users', link: '/users', permission: 'owner', order: 8 },
    { label: 'FAQ', icon: 'message-square', link: '/faq', permission: 'manager', order: 9 },
    { label: 'Help', icon: 'help-circle', link: '/help', permission: 'user', order: 10 },
    { label: 'Sign Out', icon: 'log-out', action: context.signout, permission: 'user', order: 11 }
  ])

  let Voicemails = { label: 'Voicemails', icon: 'voicemail', link: '/campaign/all/vm', permission: 'user', order: 3 }
  let Callbacks = { label: 'Callbacks', icon: 'phone-outgoing', link: '/campaign/all/cb', permission: 'user', order: 4 }

  const userVmCb = useQuery({
    queryKey: ['ui', 'vm_cb'],
    queryFn: () => axios.get('/api/ui/vm_cb').then(res => res.data)
  })

  useEffect(() => {
    if (userVmCb.data && userVmCb.isSuccess) {
      let hasVm = userVmCb.data.hasVm
      let hasCb = userVmCb.data.hasCb

      let newItems = [...items]

      if (hasVm) {
        newItems = [...newItems, Voicemails]
      }
      if (hasCb) {
        newItems = [...newItems, Callbacks]
      }

      setItems(newItems)
    }
  }, [userVmCb.data, userVmCb.isSuccess])

  const [fullyCampaign, setFullyCampaign] = useState(null)
  const io = useSocket(process.env.REACT_APP_SERVER_URL)

  useEffect(() => {
    io.on(`campaign_fully_provisioned_${context.user.id}`, (data) => {
      setFullyCampaign(data)
    })
  }, [io])

  if (userVmCb.isLoading) {
    return <></>
  }

  return (
    <Fragment>
      <AppContext.Provider value={{ userVmCb }} >
        <AppNav items={items.sort((a, b) => a.order - b.order)} />

        <main className={Style.app} style={{
          ...process.env.REACT_APP_SERVER === 'DEV' && { marginTop: '2.5rem' }
        }}>

          <Header title={props.title}>
            <User />
          </Header>

          {
            fullyCampaign && (
              <Message
                type='success'
                title={`🎉 Woo-hoo! Your Campaign ${fullyCampaign.name} is All Set! 🎉`}
              >
                <p>
                  Everything's ready to go! You can now set up your call forwarding to make sure you don't miss a beat. Just hop over to our <Link to='/faq' className='text-blue-500 underline'>FAQ</Link> page and get started!
                </p>
              </Message>
            )
          }

          {<props.children {...props.data} />}

        </main>
      </AppContext.Provider>
    </Fragment>
  );
}